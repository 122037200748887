// config.js

const dev = {
    BACKEND_URL: 'http://localhost:8001',
    FRONTEND_URL: 'http://localhost:3001'
};

const prod = {
    BACKEND_URL: 'https://www.virtualstagingai.xyz',
    FRONTEND_URL: 'https://aitattoohome.com'
};

//   const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
const cfg = prod;

export default {
...cfg
};
