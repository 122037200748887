import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Image, Sparkles, RefreshCw, MessageSquare, Share2, Info, Grid, Columns, X, ChevronUp, ChevronDown } from 'lucide-react';
import SubscriptionPlansPage from './SubscriptionPlansPage';
// import LoginPopup from './LoginPopup';
import LoginPopup from './FirebaseLogin';
import loginIcon from './placeholders/login_icon.jpg';
import ImageCard from './ImageVideoCard';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Navigation from './Navigation';
import {Button} from "@nextui-org/react";
import {Input} from "@nextui-org/react";
import {ScrollShadow} from "@nextui-org/react";
import cfg from "./config";
import {PlaceholdersAndVanishInput} from './ui/vanishinput';
import { HoverEffect } from './ui/card-hover-effect';
import {Select, SelectItem} from "@nextui-org/react";
import {FiSettings } from 'react-icons/fi'; 
import Tutorial from './Tutorial'; 

const PikaWebsite = () => {
  const [contentItems, setContentItems] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const mainRef = useRef(null);
  const [showSubscriptionPlans, setShowSubscriptionPlans] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const user = useRef(null); 
  const [uploadedImages, setUploadedImages] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [isLibraryView, setIsLibraryView] = useState(false);
  const pollingIntervalRef = useRef(null);
  const [hasWaitingTasks, setHasWaitingTasks] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false);


  const placeholders = [
    "Skull with flames and roses.",
    "Wolf with a full moon and forest background.",
    "Japanese dragon wrapped around a samurai sword.",
    "Snake coiled around a dagger with a dark background."
  ];
  const asepctratios = [
    {key: "4:3", label: "   4 : 3"},
    {key: "3:4", label: "   3 : 4"},
    {key: "1:1", label: "   1 : 1"},
    {key: "16:9", label: "   16 : 9"},
  ];
  const [selectedAspect, setSelectedAspect] = useState(null);
  const styles = [
    { key: "3d", label: "3d" },
    { key: "blackandwhite", label: "blackandwhite" },
    { key: "blackwork", label: "blackwork" },
    { key: "cartoon", label: "cartoon" },
    { key: "color", label: "color" },
    { key: "dotwork", label: "dotwork" },
    { key: "geometrical", label: "geometrical" },
    { key: "japanese", label: "japanese" },
    { key: "lettering", label: "lettering" },
    { key: "minimalist", label: "minimalist" },
    { key: "negativespace", label: "negativespace" },
    { key: "oldschool", label: "oldschool" },
    { key: "portrait", label: "portrait" },
    { key: "realistic", label: "realistic" },
    { key: "sketch", label: "sketch" },
    { key: "surreal", label: "surreal" },
    { key: "tribal", label: "tribal" },
    { key: "watercolor", label: "watercolor" },
  ];
  const [selectedStyle, setSelectedStyle] = useState(null);

  const handleAspectChange = (e) => {
    setSelectedAspect(e.target.value);
    console.log("Selected Aspect:", e.target.value); // 这将输出选中的值
  };

  const handleStyleChange = (e) => {
    setSelectedStyle(e.target.value);
    console.log("Selected Style:", e.target.value); // 这将输出选中的值
  };


  const onDrop = useCallback((acceptedFiles) => {
    const newImages = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setUploadedImages(prevImages => [...prevImages, ...newImages]);
    setIsDragging(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    noClick: true,
    noKeyboard: true
  });

  const handleDeleteImage = (index, event) => {
    event.stopPropagation(); // Prevent event from bubbling up to the dropzone
    setUploadedImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleGenerate = async (e) => {
    setPrompt(e.currentTarget[0].value);
    const formData = new FormData();
    formData.append('prompt', e.currentTarget[0].value);
    formData.append('style', selectedStyle || 'blackandwhite');
    formData.append('aspect', selectedAspect || '1:1');
    const token = localStorage.getItem('access_token');

    try {
      const response = await axios.post(`${cfg.BACKEND_URL}/generation/generate-tattoos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      console.log('Generation response:', response.data);
      // Handle the response here (e.g., display generated images)
      setHasWaitingTasks(true);  // Set this to true when a new generation starts
      if (isLibraryView && !pollingIntervalRef.current) {
        pollingIntervalRef.current = setInterval(() => fetchContentItems(true), 1000);
      }
    } catch (error) {
      console.error('Error generating images:', error);
      setShowWarning(true);
    }
  };

  const fetchContentItems = async (isLibrary = false) => {
    try {
      let url = `${cfg.BACKEND_URL}/content/api/content-items`;
      if (isLibrary && user.current) {
        url = `${cfg.BACKEND_URL}/content/generations/${user.current.email}`;
      }
      const response = await axios.get(url);
      setContentItems(response.data);

      // Check if there are any waiting tasks
      const waitingTasks = response.data.slice(0,3).some(item => item.progress < 100);
      setHasWaitingTasks(waitingTasks);

      // If there are no waiting tasks, clear the polling interval
      if (!waitingTasks && pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    } catch (error) {
      console.error('Error fetching content items:', error);
    }
  };

  useEffect(() => {
    const email = localStorage.getItem('user_email');
    const name = localStorage.getItem('user_name');
    const picture = localStorage.getItem('user_picture');

    if (email && name) {
      // setUser({ email, name, picture });
      user.current = { email, name, picture };
    } else {
      // setUser(null); // 如果 cookies 中没有用户信息，则清空 user
      user.current = null;
    }

    fetchContentItems();

  }, []);

  useEffect(() => {
    if (isLibraryView) {
      fetchContentItems(true);
      
      // Start polling only if there are waiting tasks
      if (hasWaitingTasks && !pollingIntervalRef.current) {
        pollingIntervalRef.current = setInterval(() => fetchContentItems(true), 1000);
      }
    } else {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
      fetchContentItems(false);
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [isLibraryView, hasWaitingTasks]);

  const handleLogout = () => {
    // setUser(null);
    user.current = null;
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_picture');
    localStorage.removeItem('access_token');
    setShowLoginPopup(false);
    window.location.href = `${cfg.FRONTEND_URL}`;
  };
  
  const handleLibraryClick = () => {
    const email = localStorage.getItem('user_email');
    const name = localStorage.getItem('user_name');
    const picture = localStorage.getItem('user_picture');

    if (email && name ){
      // setUser({ email, name, picture });
      user.current = { email, name, picture };
    } else {
      // setUser(null); // 如果 cookies 中没有用户信息，则清空 user
      user.current = null;
    }

    if (user.current) {
      setIsLibraryView(true);
      fetchContentItems(true);
    } else {
      setShowLoginPopup(true);
    }
  };


  const handleExploreClick = () => {
    setIsLibraryView(false);
    fetchContentItems(false);
  };


  if (showSubscriptionPlans) {
    return <SubscriptionPlansPage onBack={() => setShowSubscriptionPlans(false)} />;
  }


  return (
    <div className="bg-gradient-to-r from-blue-400 to-indigo-500 min-h-screen flex flex-col">
      
      <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-400 to-indigo-500 z-10 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">TattooHome</h1>
          <div className="flex items-center space-x-4">
            
            <Button color="secondary" variant="shadow"
              onClick={() => setShowSubscriptionPlans(true)}>
              Upgrade
            </Button>  

            <div 
              className="flex items-center space-x-2 text-white cursor-pointer"
              onClick={() => setShowLoginPopup(true)}>
              {user.current ? (
                <>
                  <img src={user.current.picture || loginIcon} alt="User avatar" className="w-8 h-8 rounded-full" />
                  <span>{user.current.name}</span>
                </>
              ) : (
                <>
                  <img src={loginIcon} alt="User avatar" className="w-8 h-8 rounded-full" />
                  <span>Log In</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-screen bg-gradient-to-r from-blue-400 to-indigo-500 rounded-lg p-10 mx-auto px-4 sm:px-6 md:px-8 flex flex-col lg:flex-row relative">
      
      
      {/* Component A - Settings Panel */}
      <div className={`
        w-full lg:w-1/4 xl:w-1/5 self-start 
        lg:mt-20 lg:pr-6 flex-shrink-0
        fixed lg:relative bottom-0 left-0 right-0 z-40
        transform transition-transform duration-300 ease-in-out
        ${isSettingsPanelOpen ? '-translate-y-0' : 'translate-y-full lg:translate-y-0'}
        bg-white lg:bg-transparent
        max-h-[80vh] lg:max-h-none
        overflow-y-auto 
      `}>
        <div className="bg-white rounded-t-3xl lg:rounded-3xl shadow-4xl p-4 sm:p-6 h-auto lg:h-[80vh]">
          <div>
            <h2 className="text-lg font-semibold mb-2 sm:mb-4">General Settings</h2>
          </div>
          <Navigation 
            viewMode={viewMode} 
            setViewMode={setViewMode} 
            onExploreClick={handleExploreClick}
            onLibraryClick={handleLibraryClick}
            isLibraryView={isLibraryView}
          />
          <Select
            items={asepctratios}
            label="Aspect Ratio"
            placeholder="Select an aspect ratio"
            className="w-3/4 sm:w-3/4 mx-auto flex justify-center items-center"
            onChange={handleAspectChange}
            data-tutorial="aspect-ratio"
          >
            {(asepctratios) => <SelectItem key={asepctratios.key}>{asepctratios.label}</SelectItem>}
          </Select>
          <div className="my-4 sm:my-6"></div>
          <Select
            items={styles}
            label="Style"
            placeholder="Select a style"
            className="w-3/4 sm:w-3/4 mx-auto flex justify-center items-center z-10"
            onChange={handleStyleChange}
            data-tutorial="style-select"
          >
            {(styles) => <SelectItem key={styles.key}>{styles.label}</SelectItem>}
          </Select>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="w-full lg:w-3/4 xl:w-4/5 flex flex-col h-full mt-4 lg:mt-0">
        {/* Component B - Image Cards */}
        <div className="flex-[6] mb-4 overflow-y-auto pb-32 lg:pb-4">
          {viewMode === 'single' ? (
            <div className="max-w-3xl mx-auto space-y-8 mt-12">
              {contentItems.map((item, index) => (
                <ImageCard
                  key={item.id}
                  id={item.id}
                  imageUrl1={item.imageUrl1}
                  imageUrl2={item.imageUrl2}
                  imageUrl3={item.imageUrl3}
                  imageUrl4={item.imageUrl4}
                  description={item.description}
                  progress={item.progress}
                  index={index}
                  isLibraryView={isLibraryView}
                  onDelete={(deletedId) => {
                    setContentItems(prevItems => prevItems.filter(item => item.id !== deletedId));
                  }}
                />
              ))}
            </div>
          ) : (
            <div>
              <div className="h-full pr-2">
                <HoverEffect items={contentItems} />
              </div>
            </div>
          )}
        </div>

        {/* Component C - Fixed at bottom on mobile */}
        <div className="hidden lg:block max-w-10xl fixed bottom-4 sm:bottom-0 left-0 right-0 relative lg:relative bg-transparent lg:bg-transparent shadow-none lg:shadow-none">
          <div className="h-full flex flex-col justify-start items-center px-2 sm:px-1 pt-1 relative z-0" data-tutorial="prompt-input">
            <PlaceholdersAndVanishInput
              placeholders={placeholders}
              onSubmit={handleGenerate}
            />
          </div>
        </div>

        {/* Mobile Settings Toggle Button - Only visible on mobile */}
        <button 
          onClick={() => setIsSettingsPanelOpen(!isSettingsPanelOpen)}
          className="lg:hidden fixed bottom-4 left-2 z-50 bg-white rounded-xl p-2 px-3 py-4 shadow-lg flex items-center gap-1"
        >
          <FiSettings size={16} />
          {isSettingsPanelOpen ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
        </button>

        <div className="lg:hidden fixed bottom-4 sm:bottom-0 left-16 right-1 z-30 bg-transparent">
          <div className="h-full flex flex-col justify-start items-center px-2 sm:px-1 pt-1" data-tutorial="prompt-input">
            <PlaceholdersAndVanishInput
              placeholders={placeholders}
              onSubmit={handleGenerate}
            />
          </div>
        </div>

      </div>
      </div>

        
      {showWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowWarning(false)}
            >
              <X size={24} />
            </button>
            <h2 className="text-xl font-bold mb-4">Warning</h2>
            <p className="mb-4">Please log in first to generate images.</p>
            <Button color="primary" onClick={() => setShowWarning(false)}>
              Close
            </Button>
          </div>
        </div>
      )}

      {showLoginPopup && (
        <LoginPopup 
          onClose={() => setShowLoginPopup(false)} 
          onLogout={handleLogout}
          mainuser={user}
        />
      )}

      {typeof window !== 'undefined' && window.innerWidth >= 1024 && (
        <div className="hidden lg:block">
          <Tutorial 
            isLibraryView={isLibraryView} 
            showLoginPopup={showLoginPopup} 
            run={window.innerWidth >= 1024}  // Only run on desktop
          />
        </div>
      )}
      
    </div>
  );
};

export default PikaWebsite;