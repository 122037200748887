import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Trash2, X, Download } from 'lucide-react';
import axios from 'axios';
import cfg from './config';

const ImageCard = ({ 
  id, 
  imageUrl1, 
  imageUrl2, 
  imageUrl3, 
  imageUrl4, 
  description, 
  progress = 0,
  index, 
  isLibraryView, 
  onDelete 
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const images = [imageUrl1, imageUrl2, imageUrl3, imageUrl4].filter(Boolean);
  const currentImage = images[currentImageIndex];
  const isGenerating = progress < 100 || images.length === 0;

  // Progress circle parameters
  const circleSize = 120;
  const strokeWidth = 8;
  const radius = (circleSize - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (Math.max(0, progress) / 100) * circumference;

  const handlePrevious = () => {
    if (!isGenerating) {
      setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
      setImageLoaded(false);
    }
  };

  const handleNext = () => {
    if (!isGenerating) {
      setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
      setImageLoaded(false);
    }
  };

  const handleDownload = async () => {
    if (isGenerating) return;
    try {
      const response = await fetch(currentImage);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileName = currentImage.split('/').pop();
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${cfg.BACKEND_URL}/content/delete/${id}`);
      onDelete(id);
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting generation:', error);
    }
  };

  const renderProgressCircle = () => (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="relative">
        <svg className="transform -rotate-90 w-32 h-32">
          <circle
            cx={circleSize / 2}
            cy={circleSize / 2}
            r={radius}
            stroke="currentColor"
            strokeWidth={strokeWidth}
            fill="transparent"
            className="text-gray-600"
          />
          <circle
            cx={circleSize / 2}
            cy={circleSize / 2}
            r={radius}
            stroke="currentColor"
            strokeWidth={strokeWidth}
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            className="text-yellow-400"
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center flex-col">
          <span className="text-2xl font-bold text-white">{Math.max(0, progress)}%</span>
          <span className="text-sm text-white mt-1">Generating...</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-800 relative rounded-2xl overflow-hidden shadow-lg flex flex-col">
      {/* Container div - Only apply aspect ratio when generating or image not loaded */}
      <div className={`relative ${(isGenerating || !imageLoaded) ? 'aspect-[16/9]' : ''}`}>
        {isGenerating ? (
          renderProgressCircle()
        ) : (
          <>
            {/* Loading placeholder with fixed aspect ratio */}
            {!imageLoaded && (
              <div className="absolute inset-0 bg-gray-700" />
            )}
            {/* Actual image that will maintain its natural ratio */}
            <img 
              src={currentImage || `/api/placeholder/400/${200 + index * 10}`}
              alt={`Generated content ${currentImageIndex + 1}`} 
              className={`w-full transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
              style={{ maxHeight: '80vh' }}
              onLoad={() => setImageLoaded(true)}
            />
          </>
        )}
        
        {/* TattooHome 1.0 text - hidden on mobile */}
        <span className="absolute top-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full text-xs font-medium hidden md:block">
          TattooHome 1.0
        </span>

        {!isGenerating && (
          <>
            <span className="absolute top-2 left-1/2 -translate-x-1/2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full text-xs md:text-xs text-[10px] font-medium">
              {currentImageIndex + 1} / {images.length}
            </span>

            <button
              className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-1 md:p-2 rounded-full hover:bg-opacity-75 transition-colors duration-200"
              onClick={handlePrevious}
            >
              <ChevronLeft size={20} />
            </button>
            <button
              className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-1 md:p-2 rounded-full hover:bg-opacity-75 transition-colors duration-200"
              onClick={handleNext}
            >
              <ChevronRight size={20} />
            </button>

            <button
              className="absolute top-2 right-2 bg-black bg-opacity-50 text-white p-1.5 md:p-2 rounded-full hover:bg-opacity-75 transition-colors duration-200"
              onClick={handleDownload}
            >
              <Download size={14} className="md:w-4 md:h-4 w-3 h-3" />
            </button>
          </>
        )}
      </div>

      <div>
        {isLibraryView && (
          <button
            className="absolute top-2 right-12 bg-black bg-opacity-50 text-white p-1.5 md:p-2 rounded-full hover:bg-opacity-75 transition-colors duration-200"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <Trash2 size={14} className="md:w-4 md:h-4 w-3 h-3" />
          </button>
        )}
      </div>

      {/* Description section - reduced padding and font size on mobile */}
      <div className="p-2 md:p-4 flex flex-col justify-between">
        <p className="text-xs md:text-sm text-gray-100">
          {description || (isGenerating ? 'Generating content...' : 'Content description goes here...')}
        </p>
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-4 md:p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg md:text-xl font-bold text-white">Confirm Deletion</h3>
              <button onClick={() => setShowDeleteConfirmation(false)} className="text-gray-400 hover:text-white">
                <X size={20} className="md:w-6 md:h-6 w-5 h-5" />
              </button>
            </div>
            <p className="text-sm md:text-base text-gray-300 mb-4 md:mb-6">Are you sure you want to delete this item? This action is irreversible.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-3 py-1.5 md:px-4 md:py-2 text-sm md:text-base bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-3 py-1.5 md:px-4 md:py-2 text-sm md:text-base bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCard;