"use client";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import React from "react";
import ImageCard from '../ImageVideoCard';
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import Masonry from "react-masonry-css";

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const HoverEffect = ({ items, className }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const breakpointColumns = {
    default: 3,    // Desktop default
    1024: 3,       // lg screens
    768: 2,        // md screens and mobile devices
    640: 2         // Keeping 2 columns for smaller screens
  };

  return (
    <div className={cn("py-10 md:px-4", className)}>
      <Masonry
        breakpointCols={breakpointColumns}
        className="flex md:-ml-2 -ml-1 w-auto" // Reduced negative margin on mobile
        columnClassName="md:pl-2 pl-1 bg-clip-padding" // Reduced padding on mobile
      >
        {items.map((item, idx) => (
          <div
            key={item?.id || idx}
            className="md:mb-2 mb-1" // Reduced bottom margin on mobile
          >
            <a
              className="relative group block md:p-2 p-1 h-full w-full" // Reduced padding on mobile
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <AnimatePresence>
                {hoveredIndex === idx && (
                  <motion.span
                    className="absolute inset-0 h-full w-full bg-neutral-200 dark:bg-slate-800/[0.8] block rounded-3xl"
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { duration: 0.15 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15, delay: 0.2 },
                    }}
                  />
                )}
              </AnimatePresence>
              <ImageCard
                key={item.id}
                id={item.id}
                imageUrl1={item.imageUrl1}
                imageUrl2={item.imageUrl2}
                imageUrl3={item.imageUrl3}
                imageUrl4={item.imageUrl4}
                description={item.description}
                progress={item.progress}
                index={idx}
              />
            </a>
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export function DropdownApp() {
  return (
    <Dropdown>
      <DropdownTrigger>
        <button className="text-gray-400 hover:text-white text-sm">• • •</button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions">
        <DropdownItem key="new">New file</DropdownItem>
        <DropdownItem key="copy">Copy link</DropdownItem>
        <DropdownItem key="edit">Edit file</DropdownItem>
        <DropdownItem key="delete" className="text-danger" color="danger">
          Delete file
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
