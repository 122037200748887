import React, { useState } from 'react';
import { ArrowLeft, CheckIcon } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import cfg from './config';

const stripePromise = loadStripe('pk_test_51OhPyJBeUr9PedFaCcYbM9RJYW26brPJhByM4yroYtngkeowrde12899ksGfFhiPbuywBVGwmG3zfrrny4PwOZ6W00suFQjHMP');

const SubscriptionPlansPage = ({ onBack, currentPlan }) => {
  const [isYearly, setIsYearly] = useState(true);

  const plans = [
    { 
      name: 'BASIC', 
      monthlyPrice: 0,
      yearlyPrice: 0,
      monthlyPriceId: 'price_1Q9KNbBeUr9PedFaILqvmJsn',
      yearlyPriceId: 'price_1Q9KNnBeUr9PedFaCsnsEHFn',
      credits: '20', 
      features: ['Free try with limited features'] 
    },
    { 
      name: 'STANDARD', 
      monthlyPrice: 10,
      yearlyPrice: 8,
      monthlyPriceId: 'price_1Q9KOhBeUr9PedFaIm6UcoFl',
      yearlyPriceId: 'price_1Q9KP3BeUr9PedFa5YVToFyM',
      credits: '160', 
      features: ['Access to TattooHome different style', 'Fast generations', 'Commercial use'] 
    },
    { 
      name: 'PRO', 
      monthlyPrice: 35,
      yearlyPrice: 28,
      monthlyPriceId: 'price_1Q9KPOBeUr9PedFatmVZNvju',
      yearlyPriceId: 'price_1Q9KPmBeUr9PedFaDCflrPZZ',
      credits: '800', 
      features: ['Access to TattooHome different style', 'Fast generations', 'Download videos with no watermark', 'Commercial use']
    },
    { 
      name: 'UNLIMITED', 
      monthlyPrice: 80,
      yearlyPrice: 72,
      monthlyPriceId: 'price_1Q9KQ7BeUr9PedFatJdYGNnl',
      yearlyPriceId: 'price_1Q9KQMBeUr9PedFaOBBP2sZf',
      credits: 'unlimited', 
      features: ['Access to TattooHome different style', 'Fast generations', 'Download videos with no watermark', 'Commercial use']
    },
  ];

  const handleSubscribe = async (plan) => {
    const stripe = await stripePromise;
    const priceId = isYearly ? plan.yearlyPriceId : plan.monthlyPriceId;

    const response = await fetch(`${cfg.BACKEND_URL}/payment/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        price_id: priceId,
        plan_name: plan.name,
      }),
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error);
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-400 to-indigo-500 min-h-screen text-white p-4 sm:p-8 font-sans">
      <div className="max-w-7xl mx-auto">
        <button onClick={onBack} className="flex items-center text-white mb-6 sm:mb-8 hover:text-white/80 transition-colors">
          <ArrowLeft size={20} className="mr-2" />
          Back
        </button>
        
        <div className="text-center mb-8 sm:mb-16">
          <h1 className="text-3xl sm:text-5xl font-bold mb-2 sm:mb-4 text-white drop-shadow-lg">PICK YOUR PLAN</h1>
          <p className="text-lg sm:text-xl text-white drop-shadow">Select a plan that fits your needs</p>
        </div>
        
        <div className="flex justify-center mb-8 sm:mb-12">
          <button 
            className={`px-4 sm:px-6 py-2 rounded-l-full transition-colors ${isYearly ? 'bg-indigo-600 text-white' : 'bg-gray-800/60 text-white hover:bg-gray-800/80'}`}
            onClick={() => setIsYearly(true)}
          >
            Yearly
          </button>
          <button 
            className={`px-4 sm:px-6 py-2 rounded-r-full transition-colors ${!isYearly ? 'bg-indigo-600 text-white' : 'bg-gray-800/60 text-white hover:bg-gray-800/80'}`}
            onClick={() => setIsYearly(false)}
          >
            Monthly
          </button>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {plans.map((plan) => (
            <div key={plan.name} className="bg-gray-900/80 backdrop-blur-sm rounded-[20px] sm:rounded-[30px] p-4 sm:p-6 flex flex-col hover:bg-gray-900/90 transition-colors border border-white/10">
              <h2 className="text-xl sm:text-2xl font-bold mb-2 text-white">{plan.name}</h2>
              <p className="text-4xl sm:text-5xl font-bold mb-2 text-white">
                ${isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                <span className="text-base sm:text-lg font-normal text-gray-300">/{isYearly ? 'month' : 'month'}</span>
              </p>
              <button 
                className="w-full py-2 sm:py-3 rounded-md mb-4 font-semibold bg-indigo-600 text-white hover:bg-indigo-700 transition-colors shadow-lg"
                onClick={() => handleSubscribe(plan)}
              >
                Subscribe to {plan.name}
              </button>
              <p className="text-lg sm:text-xl mb-4 text-white">{plan.credits} monthly credits</p>
              <ul className="space-y-2 mt-4">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-start text-gray-300 text-xs sm:text-sm">
                    <CheckIcon className="h-4 w-4 text-indigo-400 mr-2 mt-0.5 flex-shrink-0" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        
        <p className="text-xs sm:text-sm text-white mt-8 sm:mt-12 text-center drop-shadow">
          1 image credit equals one image. Each generation will generate 4 images.<br />
          Subscription monthly video credits do not roll over from month to month.
        </p>
      </div>
    </div>
  );
};

export default SubscriptionPlansPage;