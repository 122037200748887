import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import React, { useState, useEffect } from 'react';
import { X, Mail, Lock, Eye, EyeOff } from 'lucide-react';
import { 
  getAuth, 
  signInWithPopup, 
  GoogleAuthProvider, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,  // Add this import
  sendPasswordResetEmail // Optional: for password reset functionality
} from 'firebase/auth';
import cfg from './config';
import googleIcon from './placeholders/google_icon.jpg';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCHHGigDLNdw-I9teYejpHMKycP12f8P6s",
    authDomain: "tattoohome-eb8b7.firebaseapp.com",
    projectId: "tattoohome-eb8b7",
    storageBucket: "tattoohome-eb8b7.firebasestorage.app",
    messagingSenderId: "410220047176",
    appId: "1:410220047176:web:948fd2edfb1ce86a4ae17d",
    measurementId: "G-LFE1N82SEJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();

// Helper function to handle user data storage and API calls
const handleUserAuthentication = async (user, isGoogleAccount = false) => {
  if (!isGoogleAccount && !user.emailVerified) {
    throw new Error('Please verify your email before logging in');
  }
  const token = await user.getIdToken();
  
  // Store user data in localStorage
  localStorage.setItem('access_token', token);
  localStorage.setItem('user_email', user.email);
  localStorage.setItem('user_name', user.displayName || user.email);
  localStorage.setItem('user_picture', user.photoURL || '');

  // Update user data in backend
  try {
    await fetch(`${cfg.BACKEND_URL}/auth/update_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        email: user.email,
        name: user.displayName || user.email,
        picture: user.photoURL || '',
        is_google_account: isGoogleAccount
      }),
    });
  } catch (error) {
    console.error('Error updating user data:', error);
  }
};

// Main LoginPopup Component
const LoginPopup = ({ onClose, onLogout, mainuser }) => {
  const [view, setView] = useState('login');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // Get fresh user data to ensure we have the latest emailVerified status
        await firebaseUser.reload();
        const token = await firebaseUser.getIdToken();
        const userData = {
          email: firebaseUser.email,
          name: firebaseUser.displayName || firebaseUser.email,
          picture: firebaseUser.photoURL || '',
          token: token,
          emailVerified: firebaseUser.emailVerified,
          providerId: firebaseUser.providerData[0]?.providerId
        };
        setUser(userData);
        if (mainuser) mainuser.current = userData;
      } else {
        setUser(null);
        if (mainuser) mainuser.current = null;
        // Clear localStorage on logout
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_picture');
      }
    });

    return () => unsubscribe();
  }, [mainuser]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      if (mainuser) mainuser.current = null;
      onLogout();
      onClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Function to handle email verification check
  const checkEmailVerification = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        await currentUser.reload(); // Reload user to get latest verification status
        const freshUser = auth.currentUser;
        setUser(prev => ({
          ...prev,
          emailVerified: freshUser.emailVerified
        }));
      }
    } catch (error) {
      console.error('Error checking email verification:', error);
    }
  };

  // Render user profile if logged in
  if (user) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full relative">
          <button 
            onClick={onClose} 
            className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
          <h2 className="text-2xl font-bold text-white mb-4">Welcome, {user.name}!</h2>
          {user.picture && (
            <img src={user.picture} alt={user.name} className="w-20 h-20 rounded-full mx-auto mb-4" />
          )}
          <p className="text-white text-center mb-2">{user.email}</p>
          
          {user.emailVerified || user.providerId === 'google.com' ? (
            <p className="text-gray-400 text-center mb-4">You're currently logged in</p>
          ) : (
            <div className="text-center mb-4">
              <p className="text-yellow-500 mb-2">Please verify your email account first</p>
              <div className="flex flex-col gap-2">
                <button
                  onClick={async () => {
                    try {
                      if (auth.currentUser) {
                        await sendEmailVerification(auth.currentUser);
                        alert('Verification email has been sent. Please check your inbox.');
                      }
                    } catch (err) {
                      console.error('Error sending verification email:', err);
                      alert('Error sending verification email. Please try again later.');
                    }
                  }}
                  className="text-purple-500 hover:text-purple-400 text-sm"
                >
                  Resend verification email
                </button>
                <button
                  onClick={checkEmailVerification}
                  className="text-purple-500 hover:text-purple-400 text-sm"
                >
                  I've verified my email
                </button>
              </div>
            </div>
          )}
          
          <button 
            onClick={handleLogout} 
            className="w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg mt-4 transition-colors"
          >
            Log out
          </button>
        </div>
      </div>
    );
  }

  // Render different views based on state
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      {view === 'login' && (
        <LoginView 
          onCreateAccount={() => setView('create')} 
          onEmailLogin={() => setView('emailLogin')} 
          onClose={onClose} 
        />
      )}
      {view === 'emailLogin' && (
        <EmailLoginView 
          onCreateAccount={() => setView('create')} 
          onBack={() => setView('login')} 
          onClose={onClose} 
        />
      )}
      {view === 'create' && (
        <CreateAccountView 
          onLogin={() => setView('login')} 
          onClose={onClose} 
        />
      )}
    </div>
  );
};

// LoginView Component
const LoginView = ({ onCreateAccount, onEmailLogin, onClose }) => {
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      await handleUserAuthentication(result.user, true);
    } catch (error) {
      console.error('Error during Google sign in:', error);
    }
  };

  return (
    <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-white">Welcome Back</h2>
        <button onClick={onClose} className="text-gray-400 hover:text-white">
          <X size={24} />
        </button>
      </div>
      <button 
        onClick={handleGoogleLogin} 
        className="w-full bg-white text-gray-800 py-2 rounded-lg mb-4 flex items-center justify-center hover:bg-gray-100 transition-colors"
      >
        <img src={googleIcon} alt="Google" className="w-6 h-6 mr-2" />
        Continue with Google
      </button>
      <button 
        onClick={onEmailLogin} 
        className="w-full bg-gray-800 text-white py-2 rounded-lg mb-4 flex items-center justify-center hover:bg-gray-700 transition-colors"
      >
        <Mail size={20} className="mr-2" />
        Log in with Email
      </button>
      <p className="text-gray-400 text-center mt-4">
        New Around Here? <button onClick={onCreateAccount} className="text-purple-500 hover:text-purple-400">Create an Account</button>
      </p>
    </div>
  );
};

// EmailLoginView Component
const EmailLoginView = ({ onCreateAccount, onBack, onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resendVerification, setResendVerification] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      
      if (!result.user.emailVerified) {
        setResendVerification(true);
        throw new Error('Please verify your email before logging in. Check your inbox for the verification link.');
      }

      await handleUserAuthentication(result.user, false);
      onClose();
    } catch (error) {
      setError(error.message);
      console.error('Error during email sign in:', error);
    }
  };

  const handleResendVerification = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        await sendEmailVerification(currentUser);
        setError('Verification email has been resent. Please check your inbox.');
      }
    } catch (error) {
      setError('Error sending verification email. Please try again later.');
    }
  };

  return (
    <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-white">Welcome Back</h2>
        <button onClick={onClose} className="text-gray-400 hover:text-white">
          <X size={24} />
        </button>
      </div>
      {error && (
        <div className="mb-4">
          <p className="text-red-500 text-sm">{error}</p>
          {resendVerification && (
            <button
              onClick={handleResendVerification}
              className="text-purple-500 hover:text-purple-400 text-sm mt-2"
            >
              Resend verification email
            </button>
          )}
        </div>
      )}
      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <div className="relative">
            <Mail size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input 
              type="email" 
              placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-gray-800 text-white py-2 pl-10 pr-3 rounded-lg" 
            />
          </div>
        </div>
        <div className="mb-4">
          <div className="relative">
            <Lock size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input 
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-gray-800 text-white py-2 pl-10 pr-10 rounded-lg" 
            />
            <button 
              type="button"
              onClick={() => setShowPassword(!showPassword)} 
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>
        <button 
          type="submit" 
          className="w-full bg-indigo-600 text-white py-2 rounded-lg mb-4 hover:bg-indigo-700 transition-colors"
        >
          Log in
        </button>
      </form>
      <p className="text-gray-400 text-center">
        New Around Here? <button onClick={onCreateAccount} className="text-purple-500 hover:text-purple-400">Create an Account</button>
      </p>
    </div>
  );
};

// CreateAccountView Component
const CreateAccountView = ({ onLogin, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    if (!agreeToTerms) {
      setError('Please agree to the terms and conditions');
      setLoading(false);
      return;
    }

    try {
      // Create user account
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      // Send verification email
      await sendEmailVerification(result.user);
      
      // Show verification sent message
      setVerificationSent(true);
      setLoading(false);

      // Store minimal user data in backend to track pending verifications
      const token = await result.user.getIdToken();
      await fetch(`${cfg.BACKEND_URL}/auth/create_pending_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          email: result.user.email,
          is_google_account: false,
          email_verified: false
        }),
      });

    } catch (error) {
      setError(error.message);
      setLoading(false);
      console.error('Error during sign up:', error);
    }
  };

  // If verification email is sent, show a different view
  if (verificationSent) {
    return (
      <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Verify Your Email</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>
        <div className="text-center">
          <Mail size={48} className="mx-auto text-purple-500 mb-4" />
          <p className="text-white mb-4">
            We've sent a verification email to:
          </p>
          <p className="text-purple-500 font-medium mb-6">{email}</p>
          <p className="text-gray-400 mb-4">
            Please check your inbox and click the verification link to complete your registration.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="w-full bg-indigo-600 text-white py-2 rounded-lg mb-4 hover:bg-indigo-700 transition-colors"
          >
            I've verified my email
          </button>
          <button
            onClick={async () => {
              try {
                if (auth.currentUser) {
                  await sendEmailVerification(auth.currentUser);
                  setError('Verification email has been resent');
                }
              } catch (err) {
                setError('Error resending verification email');
              }
            }}
            className="text-purple-500 hover:text-purple-400"
          >
            Resend verification email
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-white">Create Account</h2>
        <button onClick={onClose} className="text-gray-400 hover:text-white">
          <X size={24} />
        </button>
      </div>
      <p className="text-white mb-4">Sign up to Get 6 🪙 Free photos</p>
      <p className="text-gray-400 mb-4">No credit card needed</p>
      {error && <p className="text-red-500 mb-4 text-sm">{error}</p>}
      <form onSubmit={handleSignUp}>
        <div className="mb-4">
          <input 
            type="email" 
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full bg-gray-800 text-white py-2 px-3 rounded-lg"
            disabled={loading}
          />
        </div>
        <div className="mb-4">
          <input 
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full bg-gray-800 text-white py-2 px-3 rounded-lg"
            disabled={loading}
          />
        </div>
        <label className="flex items-center text-gray-400 mb-4">
          <input 
            type="checkbox"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
            className="mr-2"
            disabled={loading}
          />
          I agree to the terms and conditions
        </label>
        <button 
          type="submit" 
          className={`w-full bg-indigo-600 text-white py-2 rounded-lg mb-4 hover:bg-indigo-700 transition-colors ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? 'Creating Account...' : 'Create Account'}
        </button>
      </form>
      <p className="text-gray-400 text-center mb-4">
        Already have an account? <button onClick={onLogin} className="text-purple-500 hover:text-purple-400">Log in</button>
      </p>
      <hr className="border-gray-700 mb-4" />
      <p className="text-gray-400 text-center text-sm">
        By signing up you're agreeing to AI HomeDesign's <a href="#" className="text-purple-500 hover:text-purple-400">Privacy & Policy</a> And <a href="#" className="text-purple-500 hover:text-purple-400">Terms & Conditions</a>
      </p>
    </div>
  );
};

export default LoginPopup;