import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';

const Tutorial = ({ isLibraryView, showLoginPopup }) => {
  const [runTutorial, setRunTutorial] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  
  useEffect(() => {
    // Check if it's desktop and first visit
    const checkScreen = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    // Initial check
    checkScreen();

    // Add resize listener
    window.addEventListener('resize', checkScreen);

    // Check if first visit
    const hasSeenTutorial = localStorage.getItem('hasSeenTutorial');
    if (!hasSeenTutorial && isDesktop) {
      setRunTutorial(true);
    }

    return () => window.removeEventListener('resize', checkScreen);
  }, [isDesktop]);

  // Don't render anything if not desktop
  if (!isDesktop) return null;

  const steps = [
    {
      target: '[data-tutorial="library-button"]',
      content: 'First, click here to access your library. You\'ll need to log in to generate and save tattoo designs.',
      disableBeacon: true,
      placement: 'bottom'
    },
    {
      target: '[data-tutorial="aspect-ratio"]',
      content: 'Select an aspect ratio for your tattoo design. This determines the shape and dimensions of your generated image.',
      placement: 'right'
    },
    {
      target: '[data-tutorial="style-select"]',
      content: 'Choose a style for your tattoo. Each style gives your design a unique artistic look.',
      placement: 'right'
    },
    {
      target: '[data-tutorial="prompt-input"]',
      content: 'Finally, describe the tattoo you want to create. Be specific about the design elements you want to include!',
      placement: 'top'
    }
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTutorial(false);
      localStorage.setItem('hasSeenTutorial', 'true');
    }
  };

  return (
    <Joyride
      steps={steps}
      run={runTutorial && isDesktop}
      continuous={true}
      showSkipButton={true}
      showProgress={true}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          primaryColor: '#3B82F6',
          backgroundColor: '#ffffff',
          textColor: '#333',
          arrowColor: '#ffffff',
        },
        tooltip: {
          fontSize: '15px',
          padding: '20px',
        },
        buttonNext: {
          backgroundColor: '#3B82F6',
          fontSize: '14px',
          padding: '8px 16px',
        },
        buttonBack: {
          color: '#3B82F6',
          fontSize: '14px',
          marginRight: '8px',
        },
        buttonSkip: {
          color: '#666',
          fontSize: '14px',
        }
      }}
    />
  );
};

export default Tutorial;