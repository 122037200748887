import React from 'react';
import { Tabs, Tab } from "@nextui-org/tabs";
import { Switch } from "@nextui-org/react";
import {Divider} from "@nextui-org/divider";

const Navigation = ({ viewMode, setViewMode, isLibraryView, onExploreClick, onLibraryClick }) => {
  const [isSelected, setIsSelected] = React.useState(viewMode === 'single');

  const handleSwitchChange = (selected) => {
    setIsSelected(selected);
    setViewMode(selected ? 'single' : 'grid');
  };

  const setSingle = () => {
    setIsSelected(1);
    setViewMode('single');
  };
  const setGrid = () => {
    setIsSelected(0);
    setViewMode('grid');
  };

  // Custom render function for the My Assets tab
  const renderMyAssetsTab = () => (
    <div data-tutorial="library-button">
      My Assets
    </div>
  );

  return (
    <div className="items-center">
      <div className="flex flex-wrap gap-2 sm:gap-4 justify-center">
        <Tabs 
          key="lg" 
          size="lg" 
          aria-label="Tabs sizes" 
          selectedKey={isLibraryView ? "assets" : "explore"}
          onSelectionChange={(key) => key === "assets" ? onLibraryClick() : onExploreClick()}
        >
          <Tab key="explore" title="Explore" />
          <Tab 
            key="assets" 
            title={renderMyAssetsTab()}
          />
        </Tabs>
      </div>
      
      <Divider className="my-2 sm:my-4" />

      <div className="flex flex-wrap gap-2 sm:gap-4 justify-center">
        <Tabs 
          key="lg" 
          size="lg" 
          aria-label="Tabs sizes" 
          selectedKey={isSelected ? "focus" : "relax"}
          onSelectionChange={(key) => key === "focus" ? setSingle() : setGrid()}
        >
          <Tab key="relax" title="Relaxing" />
          <Tab key="focus" title="Focusing" />
        </Tabs>
      </div>

      <Divider className="my-2 sm:my-4" />
    </div>
  );
};

export default Navigation;